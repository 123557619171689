jQuery(function($){
    
    var selectedScheme = 'theme-ELL';
	$('#color_scheme').change(function(){
	    $('body').removeClass(selectedScheme).addClass($(this).val());
	    selectedScheme = $(this).val();
	});

    
    //AOS.init();
   $('.navbar .dropdown-item').on('click', function (e) {
        var $el = $(this).children('.dropdown-toggle');
        var $parent = $el.offsetParent(".dropdown-menu");
        $(this).parent("li").toggleClass('open');

        if (!$parent.parent().hasClass('navbar-nav')) {
            if ($parent.hasClass('show')) {
                $parent.removeClass('show');
                $el.next().removeClass('show');
                $el.next().css({"top": -999, "left": -999});
            } else {
                $parent.parent().find('.show').removeClass('show');
                $parent.addClass('show');
                $el.next().addClass('show');
                $el.next().css({"top": $el[0].offsetTop, "left": $parent.outerWidth() - 4});
            }
            e.preventDefault();
            e.stopPropagation();
        }
    });

    $('.navbar .dropdown').on('hidden.bs.dropdown', function () {
        $(this).find('li.dropdown').removeClass('show open');
        $(this).find('ul.dropdown-menu').removeClass('show open');
    });
    $(".grid").imagesLoaded(function() {
	    var $grid = $(".grid").masonry({
	      itemSelector: ".grid-item"
	    });
	    var $stamp = $grid.find('.stamp');
	    $grid.masonry( 'stamp', $stamp );
	    $grid.masonry('layout');
	    $('.stamp-close').on( 'click', function() {
		  // stamp or unstamp element
		  $grid.masonry( 'unstamp', $stamp );
		  // trigger layout
		  $grid.masonry('layout');
		  $(".stamp").addClass('d-none');
		  // set flag
		  //isStamped = !isStamped;
		  
		});
		
		
	});
	
	
	
	$('[data-fancybox="gallery"]').fancybox({
		//touch: false,
		buttons: [
		    //"zoom",
		    //"share",
		    //"slideShow",
		    //"fullScreen",
		    //"download",
		    //"thumbs",
		    "close"
		],
	});
	
	
	$('.media-gallery .main-img-slider').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  infinite: true,
	  arrows: true,
	  fade:true,
	  autoplay: true,
	  autoplaySpeed: 4000,
	  speed: 300,
	  lazyLoad: 'ondemand',
	  asNavFor: '.thumb-nav',
	  prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left"></i></i><span class="sr-only sr-only-focusable">Previous</span></div>',
	  nextArrow: '<div class="slick-next"><i class="fas fa-angle-right"></i></i><span class="sr-only sr-only-focusable">Next</span></div>'
	});
	// Thumbnail/alternates slider for product page
	$('.thumb-nav').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  infinite: true,
	  centerPadding: '0px',
	  asNavFor: '.main-img-slider',
	  dots: false,
	  centerMode: false,
	  draggable: true,
	  speed:200,
	  focusOnSelect: true,
	  prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left"></i></i><span class="sr-only sr-only-focusable">Previous</span></div>',
	  nextArrow: '<div class="slick-next"><i class="fas fa-angle-right"></i><span class="sr-only sr-only-focusable">Next</span></div>'  
	});
	
	
	//keeps thumbnails active when changing main image, via mouse/touch drag/swipe
	$('.main-img-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
	  //remove all active class
	  $('.thumb-nav .slick-slide').removeClass('slick-current');
	  //set active class for current slide
	  $('.thumb-nav .slick-slide:not(.slick-cloned)').eq(currentSlide).addClass('slick-current');  
	});
	
	$("#shareIcons").jsSocials({
	    showLabel: false,
	    showCount: false,
	    shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "pinterest", "stumbleupon", "whatsapp"]
	});
	
});